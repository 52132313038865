.typing {
  position: fixed;
  bottom: 46px;
  right: 30px;
  z-index: 9999;
  height: 20px;
  width: 320px;
  font-size: 10px;
  color: black;
  text-align: right;
}
