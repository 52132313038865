@-webkit-keyframes slideIn {
  0% { left: -280px; }
  100% { left: 0; }
}

@keyframes slideIn {
  0% { left: -280px; }
  100% { left: 0; }
}

@-webkit-keyframes slideOut {
  0% { left: 0; }
  100% { left: -280px; }
}

@keyframes slideOut {
  0% { left: 0; }
  100% { left: -280px; }
}

#playlists {
  position: fixed;
  left: -280px;
  top: 0;
  z-index: 9999;
  width: 280px;
  height: 100vh;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation: slideOut 0.5s backwards;
  animation: slideOut 0.5s backwards;
}

  #playlists span.clear-friend-list {
    float: right;
    cursor: pointer;
  }

  #playlists.show {
    -webkit-animation: slideIn 0.5s forwards;
    animation: slideIn 0.5s forwards;
  }

  #playlists .playlist-toggle {
    background-color: rgba(91, 146, 187, 0.7);
    position: absolute;
    top: 0;
    right: -60px;
    z-index: 9;
    opacity: 1;
    width: 60px;
    height: 60px;
  }

    #playlists .playlist-toggle .toggle {
      display: none;
      font-size: 40px;
      text-align: center;
      padding-top: 8px;
      cursor: pointer;
    }

      #playlists .playlist-toggle .toggle.show {
        display: block;
      }

  #playlists .audio-lists-panel-header {
    height: 60px;
  }

    #playlists .audio-lists-panel-header h4 {
      padding: 15px;
    }

  #playlists .playlist-names {
    height: 100vh;
    overflow: auto;
    padding-bottom: 40px;
    -webkit-overflow-scrolling: touch;
  }

  #playlists .list-group-item {
    background-color: #40444b;
    border-bottom: #40444b;
    display: block;
    color: white;
    width: 100%;
  }

    #playlists .list-group-item:last-child {
      margin-bottom: 80px;
    }

  #playlists button {
    cursor: pointer;
    font-size: 1rem;
    padding: .45rem 1.05rem;
    border: 0;
  }

    #playlists button:hover, #playlists button.active {
      background: var(--gray);
    }

    #playlists .badge {
      padding: .3em .5em .25em .5em
    }

    #playlists .divider {
      text-transform: uppercase;
      font-weight: 700;
      font-size: .8rem;
      border: 0;
      background: var(--dark);
      cursor: default;
    }

      #playlists .divider:hover {
        background: var(--dark);
        border: 0;
        padding: .45rem 1.05rem;
      }

@media only screen and (max-width: 850px) {
  #playlists .playlist-toggle {
    right: -40px;
    width: 40px;
    height: 40px;
  }

    #playlists .playlist-toggle .toggle {
      font-size: 25px;
    }

  #playlists .audio-lists-panel-header {
    height: 40px;
  }

    #playlists .audio-lists-panel-header h4 {
      padding: 5px 15px;
    }
}
