#tracks {
  margin-top: 60px;
  margin-bottom: 110px;
}

  #tracks .dropdown-menu {
    font-size: 14px;
    min-width: 7rem;
    padding: .3rem 0;
  }

    #tracks .dropdown-menu .dropdown-item {
      padding: .3rem 1rem;
    }

  #tracks .list-group li {
    cursor: pointer;
    font-size: 14px;
  }

    #tracks .list-group button {
      cursor: pointer;
    }

    #tracks .list-group .dropdown-toggle {
      background: none;
      position: absolute;
      right: 5px;
    }

      #tracks .list-group .dropdown-toggle:hover {
        background: var(--light-gray);
      }

      #tracks .list-group .dropdown-toggle:after {
        display: none;
      }

      #tracks .list-group .list-group-item.active {
        background-color: var(--blue);
        border-color: var(--blue);
      }

        #tracks .list-group .list-group-item.active button.dropdown-toggle {
          color: var(--white);
        }

          #tracks .list-group .list-group-item.active button.dropdown-toggle:hover {
            color: var(--black);
          }

        #tracks .list-group .list-group-item:first-child,
        #tracks .list-group .list-group-item:last-child {
          border-radius: 0;
        }

        #tracks .list-group .list-group-item span {
          width: 98%;
        }

ul.unit-rating {
  width: 100% !important;
  margin: 0;
  padding: 0;
}

  ul.unit-rating li {
    display: inline-block;
    position: relative;
    z-index: 3;
  }

    ul.unit-rating li.current-rating {
      display: none;
      position: absolute;
      height: 45px;
      top: 122px;
      z-index: 1;
      background: var(--orange);
    }

    ul.unit-rating li i {
      font-size: 50px;
      margin-right: 10px;
      color: var(--orange);
    }

      ul.unit-rating li.vote i:hover {
        color: var(--yellow);
        cursor: pointer;
      }



@media only screen and (max-width: 850px) {
  #tracks {
    margin-top: 40px;
  }
}
