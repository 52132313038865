#sync {}

  ul#sync-listeners, ul#sync-tracks {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
    font-size: 14px;
  }

  .modal.sync .modal-dialog {
    max-width: 800px;
  }
