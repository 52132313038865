form.input-field {
  position: absolute;
  width: 420px;
  bottom: -45px;
  right: 0;
}

.form-control.message-input {
  width: 295px !important;
  margin-right: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 38px;
}

.toggle-emoji  {
  border-radius: 0 !important;
}

.message-submit {
  height: 38px;
}

.emoji-mart {
  position: relative;
  z-index: 999999;
}

.emoji-mart-category-list button:hover {
  background: none;
}

@media screen and (max-width: 540px) {
  form.input-field {
    position: absolute;
    width: 320px;
  }

    .form-control.message-input {
      width: 190px !important;
    }
}
