#menu {
  background-color: rgba(0, 0, 0, 0.7);
  height: 60px;
}

  #menu .navbar-nav .nav-item {
    cursor: pointer;
  }

  #menu .navbar-nav .dropdown-menu {
    position: absolute !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 53px;
    padding: 0;
  }

  #menu img.logo {
    margin-left: 65px;
  }

  #menu ul {
  }

    #menu ul > li {
    }

      #menu ul > li .custom-switch {
        color: white;
        margin-top: 15px;
        font-size: 14px;
      }

        #menu ul > li .custom-switch .custom-control-input:checked~.custom-control-label::before {
          background-color: var(--blue);
          border-color: var(--blue);
        }

      #menu ul > li > a {
        color: var(--white);
        padding: 4px 10px;
      }

        #menu ul > li > a:hover {
          background: var(--gray);
        }

        #menu ul > li > a img {
          width: 45px;
          border-width: 1px;
          margin-right: 5px;
        }

@media only screen and (max-width: 991px) {
  .logo, .navbar-nav {
    margin-top: -4px;
  }

  .dark-switch {
    position: absolute;
    top: 0;
    right: 100px;
  }
}

@media only screen and (max-width: 850px) {
  #menu {
    height: 40px;
  }

    #menu .navbar-nav .dropdown-menu {
      top: 37px;
    }

    #menu ul {
      margin-top: -8px;
    }

    #menu img.logo {
      margin-left: 45px;
      padding: 0;
      margin-top: -10px;
    }

    #menu ul > li > a img {
      width: 30px;
    }

    #menu ul > li .custom-switch {
      font-size: 11px;
    }

  .sponsor {
    display: none;
  }

  .dark-switch {
    position: absolute;
    top: -6px;
    right: 90px;
  }
}
