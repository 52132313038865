/* vars */
/*--blue: #007bff;
--indigo: #6610f2;
--purple: #6f42c1;
--pink: #e83e8c;
--red: #dc3545;
--orange: #fd7e14;
--yellow: #ffc107;
--green: #28a745;
--teal: #20c997;
--cyan: #17a2b8;
--white: #fff;
--gray: #6c757d;
--gray-dark: #343a40;
--primary: #007bff;
--secondary: #6c757d;
--success: #28a745;
--info: #17a2b8;
--warning: #ffc107;
--danger: #dc3545;
--light: #f8f9fa;
--dark: #343a40;*/
:root {
  --blue: #5b91ba;
  --light-blue: #78bdf0;
  --primary: #5B92BB;
  --green: #77af12;
  --gray: #646464;
  --orange: #e9a825;
  --light-gray: #d7d7d7;
  --lighter-gray: #f0f0f0; }

/* global styles */
html {
  min-height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, sans-serif;
  color: var(--dark);
  background: var(--light-gray);
  animation: fadein 2s;
  overflow-x: hidden;
}

#loading-modal {
  display: none;
  height: 100vh;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9; }

#loading-modal .fa {
  font-size: 80px;
  top: 40vh;
  position: relative;
  color: white; }

#loading {
  height: 100vh; }

#loading .fa {
  font-size: 80px; }

/* main elements */
a {
  color: var(--blue); }

a:hover, a:focus, a:active {
  color: var(--blue); }

img.sponsor {
  position: absolute;
  top: 0;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto; }

button:hover, button:focus, button:active,
.dropdown-item.active, .dropdown-item:active {
  background: var(--light-blue); }

strong {
  font-weight: bold; }

.no-padding {
  padding: 0; }

.no-padding-left {
  padding-left: 0; }

.no-padding-right {
  padding-right: 0; }

.supporter {
  border: 4px solid var(--green); }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab' !important; }

/* app */
#app {
  transition: all 0.2s; }

#app.active {
  opacity: .3; }

/* buttons & badges */
.btn {
  text-transform: uppercase;
  letter-spacing: .4px;
  transition: all 0.2s;
  font-size: 14px; }

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle,
.badge-primary {
  background: var(--primary);
  border-color: var(--primary); }

.btn-primary:hover {
  opacity: .8; }

.btn-sm {
  letter-spacing: .3px;
  font-size: 12px; }

.btn-link {
  color: var(--blue); }

.btn-link:hover {
  color: var(--blue); }

@-webkit-keyframes slideInLeft {
  0% {
    right: -430px; }
  100% {
    right: 0; } }
@keyframes slideInLeft {
  0% {
    right: -430px; }
  100% {
    right: 0; } }
@-webkit-keyframes slideOutLeft {
  0% {
    right: 0; }
  100% {
    right: -430px; } }
@keyframes slideOutLeft {
  0% {
    right: 0; }
  100% {
    right: -430px; } }

#chat-box {
  position: fixed;
  right: -430px;
  bottom: 0;
  z-index: 9999;
  width: 420px;
  height: 600px;
  margin-right: 10px;
  -webkit-animation: slideOutLeft 0.5s backwards;
  animation: slideOutLeft 0.5s backwards; }

#chat-box.show {
  -webkit-animation: slideInLeft 0.5s forwards;
  animation: slideInLeft 0.5s forwards; }

#chat-box .toggle-chat {
  background-color: rgba(91, 146, 187, 0.7);
  position: absolute;
  bottom: 55px;
  left: -40px;
  z-index: 9;
  opacity: 1;
  width: 40px;
  height: 38px; }

#chat-box .toggle-chat i.fa {
  color: white;
  font-size: 30px;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
  padding-left: 5px; }

.badge.alert-warning {
  background-color: var(--orange);
  color: white;
  font-size: 10px; }

.badge.alert-warning.contributor {
  font-size: 9px !important; }

.badge.alert-success {
  background-color: var(--green);
  color: white;
  font-size: 10px; }

.alert.inline {
  display: inline-block;
  text-transform: uppercase; }

.form-control {
  font-size: 14px; }

/* modals */
.modal .btn {
  font-size: 14px; }

.modal .btn:disabled {
  opacity: .2; }

.modal-full {
  min-width: 100%;
  margin: 0; }

.modal-full .modal-content {
  min-height: 100vh; }

/* fonts */
/* typography */
/* forms */
/* tables */
/* footer */
footer#footer p {
  font-size: 10px;
}

/* message */
#message {
  position: fixed;
  top: 70px;
  z-index: 10009090;
  width: 100%;
  display: none;
}

/* dark mode */
body.dark {
  background: var(--gray);
}

body.dark #menu .navbar-nav .dropdown-menu {
  background: var(--gray);
  color: white;
}

  body.dark #menu .navbar-nav .dropdown-menu a {
    color: white;
  }

    body.dark #menu .navbar-nav .dropdown-menu a:hover {
      background: var(--light-gray);
      color: var(--gray);
    }

body.dark .modal-content {
  background: var(--gray);
  color: white;
}

body.dark #track-group li {
  background: var(--gray);
  color: white;
}

  body.dark #track-group li:hover {
    background: var(--light-gray);
    color: var(--gray);
  }

  body.dark #track-group li.active:hover {
    background: var(--light-gray);
    color: white;
  }

body.dark #tracks .list-group .dropdown-toggle {
  color: white;
}

body.dark #tracks .dropdown-menu {
  background: var(--gray);
  color: white;
}

  body.dark #tracks .dropdown-menu .dropdown-item {
    color: white;
  }

    body.dark #tracks .dropdown-menu .dropdown-item:hover {
      background: var(--light-gray);
      color: var(--gray);
    }


@media screen and (max-width: 610px) {
  #chat-box {
    bottom: 50px;
  }
}
@media only screen and (max-width: 579px) {
  .box:after {
    background: none;
  }
}
@media screen and (max-width: 540px) {
  @-webkit-keyframes slideInLeft {
    0% {
      right: -330px; }
    100% {
      right: 0; } }
  @keyframes slideInLeft {
    0% {
      right: -330px; }
    100% {
      right: 0; } }
  @-webkit-keyframes slideOutLeft {
    0% {
      right: 0; }
    100% {
      right: -330px; } }
  @keyframes slideOutLeft {
    0% {
      right: 0; }
    100% {
      right: -330px; } }
  #chat-box {
    position: fixed;
    right: -330px;
    bottom: -50px;
    z-index: 9999;
    width: 320px;
    height: 600px;
    margin-right: 10px; }

  #chat-box .toggle-chat {
    bottom: 150px;
    left: -40px; }
}
