#login #main {
  height: 100vh;
}

  #login #main .alert {
    display: none;
  }

    #login #main .alert.visible {
      display: block;
      margin-top: 10px;
      padding: 5px;
    }
