.message-list {
  list-style: none;
  background: white;
  padding: 10px 10px 0 10px;
  margin: 0 0 4px 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  position: absolute;
  right: 0;
  width: 300px;
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
}

  .message-list li {
    padding-bottom: 10px;
  }

  .message-list h4, .message-list p {
    font-size: 13.5px;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .message-list h4 span {
    font-size: 11px;
    font-weight: normal;
    font-family: sans-serif;
    color: gray;
  }

  .message-list .emoji-mart-emoji {
    vertical-align: middle;
  }

  .message-list p .mention {
    color: #d24444;
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    border-top: 2px solid #d24444;
    width: 100%;
    display: block;
  }

  @media screen and (max-width: 540px) {
    .message-list {
      list-style: none;
      background: white;
      padding: 10px 10px 0 10px;
      margin: 0 0 4px 0;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      position: absolute;
      right: 0;
      width: 210px;
      max-height: 500px;
      overflow: auto;
    }
  }
