#chat-app {
  position: relative;
  width: 420px;
  right: 0;
  bottom: -45px;
}

button.toggle-emoji {
  color: white;
  background: gray;
}

#user-list {
  position: relative;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 120px;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-right: 0;
  list-style: none;
  margin: 0;
  padding: 5px;
  font-size: 13px;
}

ul#user-list h6:hover {
  cursor: pointer;
}

ul#users, ul#listeners {
  list-style: none;
  margin: 0;
  padding: 5px;
  font-size: 13px;
}

  ul#users li, ul#listeners li  {
    cursor: pointer;
  }

    ul#users li:hover, ul#listeners li:hover {
      opacity: .6;
    }

@media screen and (max-width: 540px) {
  #chat-app {
    width: 320px;
  }
}
