#music-catalog #main {
  height: 100vh;
}

  #music-catalog #main .music-catalog {
    padding-top: 60px;
  }

  .music-catalog {
    margin-bottom: 130px;
  }

  .music-catalog table td {
    position: relative;
  }

  #catalog-tracks_length select {
    float: left;
  }

  #catalog-tracks_filter label {
    display: block;
  }

  #catalog-tracks_paginate ul.pagination {
    float: right;
  }

    #catalog-tracks_paginate .page-item .page-link {
      color: var(--blue);
    }

      #catalog-tracks_paginate .page-item.active .page-link {
        background-color: var(--blue);
        border-color: var(--blue);
        color: white;
      }

  #catalog-tracks {
    background: white;
  }

    #catalog-tracks li {
      cursor: pointer;
    }

      #catalog-tracks button {
        cursor: pointer;
      }

      #catalog-tracks .dropdown-toggle {
        background: none;
        position: absolute;
        right: 0;
        top: 7px;
        color: var(--black) !important;
      }

        #catalog-tracks.dropdown-toggle:hover {
          background: var(--light-gray);
        }

        #catalog-tracks .dropdown-toggle:after {
          display: none ;
        }

          #catalog-tracks button.dropdown-toggle {
            color: var(--white);
          }

            #catalog-tracks button.dropdown-toggle:hover {
              color: var(--black);
            }

          #catalog-tracks span {
            width: 98%;
          }

@media only screen and (max-width: 767px) {
  #catalog-tracks_paginate ul.pagination {
    float: none;
  }

    #catalog-tracks_paginate ul.pagination .page-link {
      padding: .5rem;
    }
}
