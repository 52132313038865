#player {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100vw;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.95);
}

  #player .magic-playlist {
    position: absolute;
    bottom: 55px;
    left: 10px;
    border: 0;
    background: none;
    background-color: rgba(91, 146, 187, 0.7);
    width: 40px !important;
    height: 38px !important;
    text-align: center;
    z-index: 9;
    opacity: .5;
  }

  #player .magic-playlist:hover {
    opacity: 1;
  }

    #player .magic-playlist img {
      width: 28px;
    }

  #player .click-test {
    position: absolute;
    z-index: 10000000;
    top: 0;
    left: 0;
    background: white;
  }

  .cassette__media_time_progress {
    margin-right: 30px !important;
    width: 125px;
    text-align: right;
    font-size: 16px;
  }

  .audio_player .audio_time_progress {
    font-size: 16px;
    padding-right: 5px;
  }

  .audio_player .audio_info_marquee {
    width: calc(100% - 140px);
  }

  .play_pause_button {
    position: relative;
    left: 52px;
    margin-right: 100px !important;
  }

  .skip_button {
    position: absolute;
    left: 100px;
  }

    .skip_button.back {
      position: absolute;
      left: 0;
    }

  .add-new-playlist {
    display: none;
  }

@media (max-width: 669px) {
  .sponsor, .cassette__volume_control {
    display: none;
  }

  .cassette__media_info_marquee {
    width: calc(100% - 120px);
  }

  .cassette__media_time_progress {
    margin-right: 10px !important;
    width: 90px;
    font-size: 12px;
  }
}

@media (max-width: 607px) {
  #player .magic-playlist {
    bottom: 105px;
  }
}
